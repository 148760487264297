import logo from './logo.svg';
import './App.scss';
import Layout from './containers/LayOut';





// export const APIurl = "https://medsmadiagane.co.za/hss.php";
// export const BaseAPIurl = "https://medsmadiagane.co.za/";

 export const APIurl = "http://localhost:81/hss_api/hss.php";
export const BaseAPIurl = "http://localhost:81/hss_api/";


//eslint-disable-next-line no-restricted-globals 
var BaseURL = location.href;
BaseURL = BaseURL.split("/");
BaseURL = BaseURL[0] + BaseURL[1] + "//" + BaseURL[2];
export const rootUrl = BaseURL;


function App() {

  return (
    <div className="App m-0 p-0">
      <Layout />
    </div>
  );
}

export default App;
