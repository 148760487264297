import { useEffect, useState } from "react";
import APIurl from "../../App";
import axios from "axios";

import Loader from "./loader";

function Found(props) {
  const [moredetails, setMoreDetails] = useState(false);
  return (
    <>
      {moredetails ? (
        <Details dismiss={setMoreDetails} data={props.data} />
      ) : (
        <div _ngcontent-tir-c85="" className="card-body m-0 p-1">
          <div _ngcontent-tir-c85="">
            <table
              _ngcontent-tir-c85=""
              className="table table-sm table-striped table-bordered text-capitalize"
            >
              <thead _ngcontent-tir-c85="">
                <tr
                  _ngcontent-tir-c85=""
                  className="bg-primary text-white opacity-75"
                >
                  <th _ngcontent-tir-c85="" style={{ width: "150px" }}>
                    IDNo
                  </th>
                  <th _ngcontent-tir-c85="">Found in </th>
                  <th
                    _ngcontent-tir-c85=""
                    className="text-end"
                    style={{ width: "90px" }}
                  >
                    <button
                      _ngcontent-tir-c85=""
                      onClick={() => props.dismiss(false)}
                      type="button"
                      className="btn btn-secondary btn-sm"
                    >
                      Reset
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody _ngcontent-tir-c85="">
                <tr _ngcontent-tir-c85="">
                  <td _ngcontent-tir-c85="">{props.data.id_num}</td>
                  <td _ngcontent-tir-c85="">{props.data.provice}</td>
                  <td _ngcontent-tir-c85="" className="text-end">
                    <button
                      _ngcontent-tir-c85=""
                      onClick={() => setMoreDetails(true)}
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                    >
                      {" "}
                      More Detail...{" "}
                      <fa-icon _ngcontent-tir-c85="" className="ng-fa-icon">
                        <svg
                          role="img"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="file-invoice"
                          className="svg-inline--fa fa-file-invoice"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                          ></path>
                        </svg>
                      </fa-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

function Details(props) {
  const [approved, setApproved] = useState(true);
  const [data, setData] = useState(true);

  useEffect(() => {
    if (props.data.provice == "National Housing Needs Register") {
      console.log(props.data);
      setApproved(false);
      setData(props.data);
    }
  }, []);
  return (
    <>
      <div _ngcontent-tir-c85="" className="card-body m-0 p-1">
        <div _ngcontent-tir-c85="">
          <div _ngcontent-tir-c85="">
            <div _ngcontent-tir-c85="">
              <div _ngcontent-tir-c85="" className="row g-1 mt-1">
                <div _ngcontent-tir-c85="" className="col-md-4">
                  <label
                    _ngcontent-tir-c85=""
                    for="Province"
                    className="form-label mb-1 fw-bold"
                  >
                    Database:
                  </label>
                  <input
                    _ngcontent-tir-c85=""
                    type="text"
                    id="Province"
                    defaultValue={props.data.provice}
                    readOnly
                    className="form-control form-control-sm"
                  />
                </div>
                <div _ngcontent-tir-c85="" className="col-md-4">
                  <label
                    _ngcontent-tir-c85=""
                    for="Name"
                    className="form-label mb-1 fw-bold"
                  >
                    Name:
                  </label>
                  <input
                    _ngcontent-tir-c85=""
                    type="text"
                    defaultValue={props.data.Names + ", " + props.data.Surname}
                    id="Name"
                    readOnly
                    className="form-control form-control-sm"
                  />
                </div>
                <div _ngcontent-tir-c85="" className="col-md-4">
                  <label
                    _ngcontent-tir-c85=""
                    for="IDNo"
                    className="form-label mb-1 fw-bold"
                  >
                    ID Number:
                  </label>
                  <input
                    _ngcontent-tir-c85=""
                    type="text"
                    id="IDNo"
                    defaultValue={props.data.id_num}
                    readOnly
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              {approved ? (
                <>
                  <div _ngcontent-tir-c85="" className="row g-1 mt-1">
                    <div _ngcontent-tir-c85="" className="col-md-6">
                      <label
                        _ngcontent-tir-c85=""
                        for="Status"
                        className="form-label mb-1 fw-bold"
                      >
                        Status:
                      </label>
                      <input
                        _ngcontent-tir-c85=""
                        type="text"
                        id="Status"
                        defaultValue={props.data.status1}
                        readOnly
                        className="form-control form-control-sm"
                      />
                    </div>
                    <div _ngcontent-tir-c85="" className="col-md-6">
                      <label
                        _ngcontent-tir-c85=""
                        id="ApplType"
                        className="form-label mb-1 fw-bold"
                      >
                        Application Type:
                      </label>
                      <input
                        _ngcontent-tir-c85=""
                        type="text"
                        id="ApplType"
                        defaultValue={props.data.Application_Type}
                        readOnly
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>

                  <div _ngcontent-tir-c85="" className="row g-1 mt-1">
                    <div _ngcontent-tir-c85="" className="col-md-8">
                      <label
                        _ngcontent-tir-c85=""
                        for="Area"
                        className="form-label mb-1 fw-bold"
                      >
                        Area:
                      </label>
                      <input
                        _ngcontent-tir-c85=""
                        type="text"
                        id="Area"
                        defaultValue={props.data.Area}
                        readOnly
                        className="form-control form-control-sm"
                      />
                    </div>
                    <div _ngcontent-tir-c85="" className="col-md-4">
                      <label
                        _ngcontent-tir-c85=""
                        for="SiteNo"
                        className="form-label mb-1 fw-bold"
                      >
                        Site Number:
                      </label>
                      <input
                        _ngcontent-tir-c85=""
                        type="text"
                        defaultValue={props.data.Site_Number}
                        id="SiteNo"
                        readOnly
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>

                  <div _ngcontent-tir-c85="" className="row g-1 mt-1">
                    <div _ngcontent-tir-c85="" className="col-md-12">
                      <label
                        _ngcontent-tir-c85=""
                        for="PhaseNo"
                        className="form-label mb-1 fw-bold"
                      >
                        Project:
                      </label>
                      <input
                        _ngcontent-tir-c85=""
                        type="text"
                        defaultValue={props.data.Project}
                        id="PhaseNo"
                        readOnly
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div _ngcontent-tir-c85="" className="row g-1 mt-1">
                    <div _ngcontent-tir-c85="" className="col-md-9">
                      <label
                        _ngcontent-tir-c85=""
                        for="ConstStatus"
                        className="form-label mb-1 fw-bold"
                      >
                        Construction Status:
                      </label>
                      <input
                        _ngcontent-tir-c85=""
                        type="text"
                        defaultValue={"Not Stipulated"}
                        id="ConstStatus"
                        readOnly
                        className="form-control form-control-sm"
                      />
                    </div>
                    <div _ngcontent-tir-c85="" className="col-md-3">
                      <label
                        _ngcontent-tir-c85=""
                        for="HappyLetter"
                        className="form-label mb-1 fw-bold"
                      >
                        Happy Letter:
                      </label>
                      <div
                        _ngcontent-tir-c85=""
                        className="form-check ms-5"
                        style={{ width: "25px" }}
                      >
                        <input
                          _ngcontent-tir-c85=""
                          id="HappyLetter"
                          type="checkbox"
                          disabled
                          className="form-check-input"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row g-1 mt-1 mx-0">
                    <div className="col-md-4">
                      <label
                        for="registration_date"
                        className="form-label mb-1 fw-bold"
                      >
                        Registration Date:
                      </label>
                      {/* <input
                        type="text"
                        defaultValue={data.registration_date}
                        id="registration_date"
                        readOnly
                        className="form-control form-control-sm"
                      /> */}
                      <div className="form-control form-control-sm">
                        {data.registration_date}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <label for="Area1" className="form-label mb-1 fw-bold">
                        Area:
                      </label>

                      <div
                        className="form-control form-control-sm overflow-hide"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {data.Area}
                      </div>
                    </div>
                  </div>

                  <div className="row g-1 mt-1">
                    <div className="col-md-4">
                      <label for="position" className="form-label mb-1 fw-bold">
                        Position in Area:
                      </label>

                      <div className="form-control form-control-sm overflow-none">
                        {data.position + "/" + data.ID}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <label for="Area" className="form-label mb-1 fw-bold">
                        In Allocation:
                      </label>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12 text-end">
              <button
                _ngcontent-tir-c85=""
                onClick={() => props.dismiss(false)}
                className="btn btn-outline-secondary btn-sm"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function Body() {
  const [OTP, setOTP] = useState(null);
  const [loader, setLoader] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isFound, setisFound] = useState(false);
  const [details, setDetails] = useState({});

  const process = (event) => {
    event.preventDefault();
    setLoader(true);

    let source = document.getElementById("idNumber").value;

    var ok = 1;

    if (ok == 1) {
      axios
        .post("https://medsmadiagane.co.za/hss.php", {
          cmd: "search",
          id: source,
        })
        .then((response) => {
          console.log(response.data);

          document.getElementById("register_form").reset();

          var res = response.data;

          if (!res["success"]) {
            setNotFound(true);
            setLoader(false);
            setisFound(false);
          } else {
            setNotFound(false);
            setLoader(false);
            setisFound(true);
            setDetails(res["data"][0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Please fix errors!");
    }
  };

  return (
    <>
      {loader && <Loader />}

      <div _ngcontent-tir-c140="" className="container-fluid mt-1 mb-5">
        <router-outlet _ngcontent-tir-c140=""></router-outlet>
        <app-home _nghost-tir-c86="">
          <div _ngcontent-tir-c86="" className="row g-0 d-none d-sm-block">
            <div _ngcontent-tir-c86="" className="col-md-12">
              <img
                _ngcontent-tir-c86=""
                height="120px"
                alt="Housing Constrution Image"
                className="d-block w-100"
                src="https://www.hssonline.gov.za/assets/images/NDHSProjectPicture0.png"
              />
            </div>
          </div>
          <div _ngcontent-tir-c86="" className="row mt-1">
            <div _ngcontent-tir-c86="" className="col-md-3">
              <div _ngcontent-tir-c86="" className="card">
                <div _ngcontent-tir-c86="" className="card-header myGradient">
                  Portal Login
                </div>
                <div _ngcontent-tir-c86="" className="card-body m-0 p-1">
                  <form
                    _ngcontent-tir-c86=""
                    noValidate=""
                    autoComplete="off"
                    action="#"
                    className="needs-validation ng-untouched ng-pristine "
                  >
                    <div _ngcontent-tir-c86="" className="row g-1">
                      <div _ngcontent-tir-c86="" className="col-md-12">
                        <input
                          _ngcontent-tir-c86=""
                          type="text"
                          id="email"
                          formcontrolname="email"
                          autoComplete="new-password"
                          placeholder="Email address"
                          className="form-control form-control-sm  ng-untouched ng-pristine "
                          required
                        />
                      </div>
                      <div _ngcontent-tir-c86="" className="col-md-12">
                        <input
                          _ngcontent-tir-c86=""
                          type="password"
                          id="password"
                          formcontrolname="password"
                          autoComplete="new-password"
                          placeholder="Password"
                          className="form-control form-control-sm  ng-untouched ng-pristine "
                          required
                        />
                      </div>
                      <div _ngcontent-tir-c86="" className="col-md-12">
                        <label
                          _ngcontent-tir-c86=""
                          htmlFor="otp"
                          className="form-label"
                        >
                          OTP:
                          <span
                            _ngcontent-cva-c86=""
                            style={{ fontWeight: "bold", color: "purple" }}
                          >
                            {" "}
                            {OTP}
                          </span>
                        </label>
                        <input
                          onClick={() =>
                            setOTP(Math.round(Math.random() * 10000))
                          }
                          _ngcontent-tir-c86=""
                          type="text"
                          id="otp"
                          formcontrolname="otp"
                          autoComplete="off"
                          placeholder="Enter OTP"
                          className="form-control form-control-sm  ng-untouched ng-pristine "
                          required
                        />
                      </div>

                      <div _ngcontent-tir-c86="" className="row g-1 mt-2">
                        <div
                          _ngcontent-tir-c86=""
                          className="col-md-12 text-end"
                        >
                          <div
                            _ngcontent-tir-c86=""
                            className="btn-group btn-group-sm"
                          >
                            <button
                              _ngcontent-tir-c86=""
                              type="submit"
                              className="btn btn-outline-primary"
                              disabled=""
                            >
                              Submit
                            </button>
                            <button
                              _ngcontent-tir-c86=""
                              type="reset"
                              className="btn btn-outline-secondary"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tir-c86="" className="row g-1 mt-1">
                        <div
                          _ngcontent-tir-c86=""
                          className="col-md-12 text-end"
                        >
                          <button
                            _ngcontent-tir-c86=""
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            disabled=""
                          >
                            Forgot Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div _ngcontent-tir-c86="" className="col-md-6">
              <app-my-housing-subsidy _ngcontent-tir-c86="" _nghost-tir-c85="">
                <div _ngcontent-tir-c85="" className="card">
                  <div _ngcontent-tir-c85="" className="card-header myGradient">
                    My Housing Subsidy
                  </div>
                  {isFound ? (
                    <Found dismiss={setisFound} data={details} />
                  ) : (
                    <div _ngcontent-tir-c85="" className="card-body m-0 p-1">
                      <div _ngcontent-tir-c85="">
                        <form
                          _ngcontent-tir-c85=""
                          noValidate=""
                          method="post"
                          id="register_form"
                          onSubmit={process}
                          className="ng-untouched ng-pristine "
                        >
                          <div _ngcontent-tir-c85="" className="form-group">
                            <label
                              _ngcontent-tir-c85=""
                              style={{ fontSize: "11px" }}
                            >
                              {" "}
                              Enter your ID Number in the box to see the status
                              of your Housing Subsidy.
                            </label>
                            <br _ngcontent-tir-c85="" />
                            <div
                              _ngcontent-tir-c85=""
                              className="input-group input-group-sm"
                            >
                              <input
                                _ngcontent-tir-c85=""
                                type="text"
                                placeholder="ID Number"
                                minLength="13"
                                id="idNumber"
                                name="idNumber"
                                autoComplete="off"
                                className="form-control form-control-sm ng-untouched ng-pristine "
                                style={{ maxWidth: "250px" }}
                                required
                              />
                              <button
                                _ngcontent-tir-c85=""
                                type="submit"
                                className="btn btn-outline-primary btn-sm"
                                style={{ marginLeft: "2px" }}
                                disabled=""
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </form>
                        {notFound ? (
                          <div
                            _ngcontent-tir-c85=""
                            id="msgerr"
                            className="alert alert-warning"
                          >
                            ID Number not found!
                            <button
                              _ngcontent-tir-c85=""
                              onClick={() => setNotFound(false)}
                              type="button"
                              aria-label="Close"
                              className="btn-close"
                              style={{ float: "right" }}
                            ></button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </app-my-housing-subsidy>
            </div>
            <div _ngcontent-tir-c86="" className="col-md-3">
              <div _ngcontent-tir-c86="" className="card">
                <div _ngcontent-tir-c86="" className="card-header myGradient">
                  Provincial DBs availibility
                </div>
                <div _ngcontent-tir-c86="" className="card-body m-0 p-1">
                  <div _ngcontent-tir-c86="" className="row g-1">
                    <div _ngcontent-tir-c86="" className="col-md-4">
                      <div
                        _ngcontent-tir-c86=""
                        style={{
                          display: "block",
                          minWidth: "45px",
                          maxWidth: "50px",
                        }}
                      >
                        {" "}
                        EC{" "}
                        <span
                          _ngcontent-tir-c86=""
                          className="float-end text-success"
                        >
                          <fa-icon _ngcontent-tir-c86="" className="ng-fa-icon">
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plug-circle-plus"
                              className="svg-inline--fa fa-plug-circle-plus"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                              ></path>
                            </svg>
                          </fa-icon>
                        </span>
                      </div>
                    </div>
                    <div _ngcontent-tir-c86="" className="col-md-4">
                      <div
                        _ngcontent-tir-c86=""
                        style={{
                          display: "block",
                          minWidth: "45px",
                          maxWidth: "50px",
                        }}
                      >
                        {" "}
                        FS{" "}
                        <span
                          _ngcontent-tir-c86=""
                          className="float-end text-success"
                        >
                          <fa-icon _ngcontent-tir-c86="" className="ng-fa-icon">
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plug-circle-plus"
                              className="svg-inline--fa fa-plug-circle-plus"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                              ></path>
                            </svg>
                          </fa-icon>
                        </span>
                      </div>
                    </div>
                    <div _ngcontent-tir-c86="" className="col-md-4">
                      <div
                        _ngcontent-tir-c86=""
                        style={{
                          display: "block",
                          minWidth: "45px",
                          maxWidth: "50px",
                        }}
                      >
                        {" "}
                        GP{" "}
                        <span
                          _ngcontent-tir-c86=""
                          className="float-end text-success"
                        >
                          <fa-icon _ngcontent-tir-c86="" className="ng-fa-icon">
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plug-circle-plus"
                              className="svg-inline--fa fa-plug-circle-plus"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                              ></path>
                            </svg>
                          </fa-icon>
                        </span>
                      </div>
                    </div>
                    <div _ngcontent-tir-c86="" className="col-md-4">
                      <div
                        _ngcontent-tir-c86=""
                        style={{
                          display: "block",
                          minWidth: "45px",
                          maxWidth: "50px",
                        }}
                      >
                        {" "}
                        KZ{" "}
                        <span
                          _ngcontent-tir-c86=""
                          className="float-end text-success"
                        >
                          <fa-icon _ngcontent-tir-c86="" className="ng-fa-icon">
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plug-circle-plus"
                              className="svg-inline--fa fa-plug-circle-plus"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                              ></path>
                            </svg>
                          </fa-icon>
                        </span>
                      </div>
                    </div>
                    <div _ngcontent-tir-c86="" className="col-md-4">
                      <div
                        _ngcontent-tir-c86=""
                        style={{
                          display: "block",
                          minWidth: "45px",
                          maxWidth: "50px",
                        }}
                      >
                        {" "}
                        LP{" "}
                        <span
                          _ngcontent-tir-c86=""
                          className="float-end text-success"
                        >
                          <fa-icon _ngcontent-tir-c86="" className="ng-fa-icon">
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plug-circle-plus"
                              className="svg-inline--fa fa-plug-circle-plus"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                              ></path>
                            </svg>
                          </fa-icon>
                        </span>
                      </div>
                    </div>
                    <div _ngcontent-tir-c86="" className="col-md-4">
                      <div
                        _ngcontent-tir-c86=""
                        style={{
                          display: "block",
                          minWidth: "45px",
                          maxWidth: "50px",
                        }}
                      >
                        {" "}
                        MP{" "}
                        <span
                          _ngcontent-tir-c86=""
                          className="float-end text-success"
                        >
                          <fa-icon _ngcontent-tir-c86="" className="ng-fa-icon">
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plug-circle-plus"
                              className="svg-inline--fa fa-plug-circle-plus"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                              ></path>
                            </svg>
                          </fa-icon>
                        </span>
                      </div>
                    </div>
                    <div _ngcontent-tir-c86="" className="col-md-4">
                      <div
                        _ngcontent-tir-c86=""
                        style={{
                          display: "block",
                          minWidth: "45px",
                          maxWidth: "50px",
                        }}
                      >
                        {" "}
                        NC{" "}
                        <span
                          _ngcontent-tir-c86=""
                          className="float-end text-success"
                        >
                          <fa-icon _ngcontent-tir-c86="" className="ng-fa-icon">
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plug-circle-plus"
                              className="svg-inline--fa fa-plug-circle-plus"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                              ></path>
                            </svg>
                          </fa-icon>
                        </span>
                      </div>
                    </div>
                    <div _ngcontent-tir-c86="" className="col-md-4">
                      <div
                        _ngcontent-tir-c86=""
                        style={{
                          display: "block",
                          minWidth: "45px",
                          maxWidth: "50px",
                        }}
                      >
                        {" "}
                        NW{" "}
                        <span
                          _ngcontent-tir-c86=""
                          className="float-end text-success"
                        >
                          <fa-icon _ngcontent-tir-c86="" className="ng-fa-icon">
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plug-circle-plus"
                              className="svg-inline--fa fa-plug-circle-plus"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                              ></path>
                            </svg>
                          </fa-icon>
                        </span>
                      </div>
                    </div>
                    <div _ngcontent-tir-c86="" className="col-md-4">
                      <div
                        _ngcontent-tir-c86=""
                        style={{
                          display: "block",
                          minWidth: "45px",
                          maxWidth: "50px",
                        }}
                      >
                        {" "}
                        WC{" "}
                        <span
                          _ngcontent-tir-c86=""
                          className="float-end text-success"
                        >
                          <fa-icon _ngcontent-tir-c86="" className="ng-fa-icon">
                            <svg
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plug-circle-plus"
                              className="svg-inline--fa fa-plug-circle-plus"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C78.3 0 64 14.3 64 32v96h64V32c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32v96h64V32c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32v32c0 77.4 55 142 128 156.8V480c0 17.7 14.3 32 32 32s32-14.3 32-32V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.3 53.8-148 127.3-169.2c.5-2.2 .7-4.5 .7-6.8c0-17.7-14.3-32-32-32H32zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm16-208v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                              ></path>
                            </svg>
                          </fa-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-home>
      </div>
    </>
  );
}
