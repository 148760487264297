import img1 from "../../assets/images/NDHSLogo75.png"
import img2 from "../../assets/images/HSSOnlineLogo75.jpg"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";

export default function Header() {

    const [Launch1, setmnuQuickLaunch ] = useState(false);
    const [Launch2, setmnuQuickLaunch1 ] = useState(false);
    const [Launch3, setmnuQuickLaunch2 ] = useState(false);
    const [main, setMain] = useState(false);
    return (
        <><div _ngcontent-tir-c140="" className="container-fluid">
        <div _ngcontent-tir-c140="" className="row">
          <div _ngcontent-tir-c140="" className="col-3 d-none d-md-block">
            <img _ngcontent-tir-c140="" src={img1} alt="..." className="img-fluid" />
          </div>
          <div _ngcontent-tir-c140="" className="col-md-6 col-sm-12 text-center align-text-bottom">
            <h1 _ngcontent-tir-c140="" className="title">Human Settlements System Online</h1>
          </div>
          <div _ngcontent-tir-c140="" className="col-3 d-none d-md-block text-end">
            <img _ngcontent-tir-c140="" src={img2} alt="..." className="img-fluid" />
          </div>
        </div>
      </div>
      <nav _ngcontent-tir-c140="" className="navbar navbar-expand-lg primary p-0">
        <div _ngcontent-tir-c140="" className="container-fluid p-0">
          <button _ngcontent-tir-c140="" onClick={()=>setMain(!main)} type="button" data-bs-toggle="collapse" data-bs-target="#mainNavbar" aria-controls="mainNavbar" aria-expanded="true" aria-label="Toggle navigation " className="navbar-toggler">
            <span _ngcontent-tir-c140="" className="navbar-toggler-icon mySmallNav"></span>
          </button>
          {main? 
          <div _ngcontent-tir-c140="" id="mainNavbar" className="navbar-collapse p-1 collapse show">
            <ul _ngcontent-tir-c140="" className="navbar-nav me-auto">
              <li _ngcontent-tir-c140="" className="nav-item active">
                <Link _ngcontent-tir-c140="" routerlink="" title="Home" className="nav-link" to="/">
                  <fa-icon _ngcontent-tir-c140="" size="1x" className="ng-fa-icon">
                    <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="house-user" className="svg-inline--fa fa-house-user fa-1x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                      <path fill="currentColor" d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c.2 35.5-28.5 64.3-64 64.3H128.1c-35.3 0-64-28.7-64-64V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24zM352 224a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zm-96 96c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H256z"></path>
                    </svg>
                  </fa-icon>
                </Link>
              </li>
              <li _ngcontent-tir-c140="" data-bs-auto-close="true" className="nav-item dropdown">
                <ul _ngcontent-tir-c140="" aria-labelledby="mnuReports" className="dropdown-menu">
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                </ul>
              </li>
              <li _ngcontent-tir-c140="" data-bs-auto-close="true" className="nav-item dropdown">
                <ul _ngcontent-tir-c140="" aria-labelledby="mnuApplications" className="dropdown-menu">
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                </ul>
              </li>
              <li _ngcontent-tir-c140="" data-bs-auto-close="true" className="nav-item dropdown">
                <ul _ngcontent-tir-c140="" aria-labelledby="mnuProjManagement" className="dropdown-menu">
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                </ul>
              </li>
              <li _ngcontent-tir-c140="" data-bs-auto-close="true" className="nav-item dropdown">
                <ul _ngcontent-tir-c140="" aria-labelledby="mnuSearchModule" className="dropdown-menu">
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                </ul>
              </li>
              <li _ngcontent-tir-c140="" data-bs-auto-close="true" className="nav-item dropdown">
                <ul _ngcontent-tir-c140="" aria-labelledby="mnuSearchModule" className="dropdown-menu">
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                  <li _ngcontent-tir-c140=""></li>
                </ul>
              </li>
            </ul>
            <ul _ngcontent-tir-c140="" className="navbar-nav justify-content-end">
              <li _ngcontent-tir-c140="" data-bs-auto-close="true" className="nav-item dropdown"  onClick={()=>setmnuQuickLaunch(!Launch1)}>
                <a _ngcontent-tir-c140="" role="button" aria-expanded="false" title="Quick Launch" data-bs-toggle="dropdown" className="nav-link dropdown-toggle">
                  <fa-icon _ngcontent-tir-c140="" size="1x" className="ng-fa-icon">
                    <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="rocket" className="svg-inline--fa fa-rocket fa-1x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="currentColor" d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"></path>
                    </svg>
                  </fa-icon>
                </a>
                {Launch1? 
                <ul _ngcontent-tir-c140="" id="mnuQuickLaunch" className={"dropdown-menu show dropdown-menu-end "}>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" className="dropdown-item disabled">Version 4.01.01</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="http://www.dhs.gov.za/" target="blank" className="dropdown-item">National Department of Human Settlements</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="https://nhnr.hssonline.gov.za/" target="blank" className="dropdown-item">National Housing Needs Register (NHNR)</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="https://ndhs.hssonline.gov.za/ChangeRequest/default.aspx" target="blank" className="dropdown-item">HSS Change Control</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="https://ndhs.hssonline.gov.za/HelpDesk" target="blank" className="dropdown-item">HSS Help Desk</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/uploads/SearchBatchSample.xlsx" target="blank" className="dropdown-item">Search Batch Template</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="http://www.ecdhs.gov.za/" target="blank" className="dropdown-item">Eastern Cape Housing</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="http://www.humansettlements.fs.gov.za/" target="blank" className="dropdown-item">Free State Housing</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="https://www.gauteng.gov.za/Departments/DepartmentDetails/CPM-001007" target="blank" className="dropdown-item">Gauteng Housing</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="http://www.kzndhs.gov.za/" target="blank" className="dropdown-item">Kwazulu-Natal Housing</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="http://www.coghsta.limpopo.gov.za/index.php" target="blank" className="dropdown-item">Limpopo Housing</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="http://dhs.mpg.gov.za/" target="blank" className="dropdown-item">Mpumalanga Housing</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="http://www.nwpg.gov.za/HumanSettlements/site/index.html" target="blank" className="dropdown-item">North West Housing</a>
                  </li>
                  <li _ngcontent-tir-c140="">
                    <a _ngcontent-tir-c140="" href="https://www.westerncape.gov.za/dept/human-settlements" target="blank" className="dropdown-item">Western Cape Housing</a>
                  </li>
                </ul>: '' }
              </li>
              <li _ngcontent-tir-c140="" data-bs-auto-close="true" className="nav-item dropdown" onClick={()=>setmnuQuickLaunch1(!Launch2)}>
                <a _ngcontent-tir-c140=""  role="button" aria-expanded="false" title="Documents" data-bs-toggle="dropdown" className="nav-link dropdown-toggle">
                  <fa-icon _ngcontent-tir-c140="" size="1x" className="ng-fa-icon">
                    <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="folder" className="svg-inline--fa fa-folder fa-1x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="currentColor" d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z"></path>
                    </svg>
                  </fa-icon>
                </a>
                {Launch2?
                 <ul _ngcontent-tir-c140="" id="mnuDocuments" className="dropdown-menu show dropdown-menu-end">
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="http://www.dhs.gov.za/content/national-housing-code-2009" target="blank" className="dropdown-item">The National Housing Code</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <h6 _ngcontent-tir-c140="" className="dropdown-header">Red Book Planning and Design Guide</h6>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/Navigation_Human Settlements.pdf" target="blank" className="dropdown-item">Navigation Human Settlements</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <h6 _ngcontent-tir-c140="" className="dropdown-header">Part I</h6>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/PART I_PLANNING AND DESIGN GUIDE.pdf" target="blank" className="dropdown-item">Sections A-E</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <h6 _ngcontent-tir-c140="" className="dropdown-header">Part II</h6>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION F_NEIGHBOURHOOD LAYOUT AND STRUCTURE.pdf" target="blank" className="dropdown-item">Section F Neighbourhood layout and structure</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION G_PUBLIC OPEN SPACE.pdf" target="blank" className="dropdown-item">Section G Public open space</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION H_HOUSING AND SOCIAL FACILITIES.pdf" target="blank" className="dropdown-item">Section H Housing and Social facilities</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION I_TRANSPORTATION AND ROAD PAVEMENTS.pdf" target="blank" className="dropdown-item">Section I Transportation and Road pavements</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION J_WATER SUPPLY.pdf" target="blank" className="dropdown-item">Section J Water supply</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION K_SANITATION.pdf" target="blank" className="dropdown-item">Section K Sanitation</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION L_STORMWATER.pdf" target="blank" className="dropdown-item">Section L Stormwater</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION M_SOLID WASTE MANAGEMENT.pdf" target="blank" className="dropdown-item">Section M Solid waste management</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION N_ELECTRICAL ENERGY.pdf" target="blank" className="dropdown-item">Section N Electrical Energy</a>
                 </li>
                 <li _ngcontent-tir-c140="">
                   <a _ngcontent-tir-c140="" href="https://www.hssonline.gov.za/assets/docs/SECTION O_CROSS-CUTTING ISSUES.pdf" target="blank" className="dropdown-item">Section O Cross cutting issues</a>
                 </li>
               </ul>: ''
                }
               
              </li>
              <li _ngcontent-tir-c140="" className="nav-item">
                <Link _ngcontent-tir-c140="" routerlink="/Security/RegisterUser" title="Register" className="nav-link" to="register">
                  <fa-icon _ngcontent-tir-c140="" size="1x" className="ng-fa-icon">
                    <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="link" className="svg-inline--fa fa-link fa-1x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                      <path fill="currentColor" d="M562.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L405.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C189.5 251.2 196 330 246 380c56.5 56.5 148 56.5 204.5 0L562.8 267.7zM43.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C57 372 57 321 88.5 289.5L200.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C416.5 260.8 410 182 360 132c-56.5-56.5-148-56.5-204.5 0L43.2 244.3z"></path>
                    </svg>
                  </fa-icon>
                </Link>

              </li>
            </ul>
          </div>:''}
        </div>
      </nav>
        </>
    )
}