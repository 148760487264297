


export default function Footer(){

    return (
        <>
        <nav _ngcontent-tir-c140="" className="navbar fixed-bottom bg-dark">
  
  <ul _ngcontent-tir-c140="" className="navbar-nav flex-row ms-auto">
    <li _ngcontent-tir-c140="" className="nav-item me-5">
      <a _ngcontent-tir-c140="" routerlink="/Docs/Disclaimer" className="nav-link text-danger" href="#/Docs/Disclaimer">Disclaimer and Conditions of Use</a>
    </li>
    <li _ngcontent-tir-c140="" className="nav-item me-2">
      <a _ngcontent-tir-c140="" routerlink="/ContactUs" className="nav-link text-primary" href="#/ContactUs">Contact Us</a>
    </li>
  </ul>
</nav>
        </>
    )
}