import { useLoaderData } from "react-router-dom";

export default function Loader(){
    return (<>
    <app-loading _ngcontent-tir-c140="" _nghost-tir-c139="">
  <div _ngcontent-tir-c139="" className="loader">
    <div _ngcontent-tir-c139="" className="loader-content">
      <fa-icon _ngcontent-tir-c139="" size="5x" className="ng-fa-icon">
        <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" className="svg-inline--fa fa-spinner fa-spin fa-5x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M304 48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zm0 416c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM48 304c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm464-48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM142.9 437c18.7-18.7 18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zm0-294.2c18.7-18.7 18.7-49.1 0-67.9S93.7 56.2 75 75s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zM369.1 437c18.7 18.7 49.1 18.7 67.9 0s18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9z"></path>
        </svg>
      </fa-icon>
    </div>
  </div>
  
</app-loading>
    </>)
}

