import React from 'react'
import Header from '../Header'
import './index.scss'

import { Route, Routes, Outlet, BrowserRouter } from 'react-router-dom'
import Body from '../Body'
import Footer from '../Footer'
import NoPage from '../NoPage'
// import Vacancies from "../Vacancies";
// import BookingPage from "../BookingPage";
// import ManagementSystem from "../ManagementSystemPage";
// import Gallery from "../Gallery";
// import AddPhoto from "../AddPhoto";
// import JobApplicationPage from "../JobApplication";
// import AddNewJobPage from "../AddNewJob";
// import PhotoDelete from '../PhotoDelete'
// import JobDelete from '../JobDelete'

function Layout() {

    return (
        <>
            <BrowserRouter>
            <Header/>
         
                <Routes>
                    <Route index element={<Body />}/>
                    <Route path="/check" element={<Body />}/>
                    <Route path="hss/check" element={<Body />}/>
                    {/* <Route path="/home" element={<Body />}/>
                    <Route path="/booking" element={<BookingPage />}/>
                    <Route path="/vacancies" element={<Vacancies />}/>
                    <Route path={'/management'} element={<ManagementSystem/>}/>
                    <Route path={'/management/deletephoto'} element={<PhotoDelete/>}/>
                    <Route path={'/management/deletejob'} element={<JobDelete/>}/>
                    <Route path={'/application'} element={<JobApplicationPage/>}/>
                    <Route path={'/gallery'} element={<Gallery/>}/>
                    <Route path={'/addPhoto'} element={<AddPhoto/>}/>
                    <Route path={'/addJob'} element={<AddNewJobPage/>}/>*/}
                    <Route path="*" element={<NoPage />}/> 
                </Routes>
            </BrowserRouter>
            <Footer/>
            <Outlet />
        </>
    );
}


export default Layout